import { IControl } from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from 'antd';
import './styles.scss';
import { Icon, IconName, EIconTypes } from '../../..';
import { ConditionalWrapper } from '../../../ConditionalWrapper';

export interface IMapboxControlOptions {
	id: string;
	icon?: IconName;
	title?: string;
	rawIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	activeIcon?: IconName | undefined;
	onClick?: (isActive?: boolean) => void;
	setActive?: boolean;
	rotateIcon?: boolean;
	className?: string | undefined;
	buttonGroup?: string;
	tooltip?: string;
}
interface IMapboxButton extends IMapboxControlOptions {
	iconType?: EIconTypes;
	map: mapboxgl.Map;
}

const MapboxButton: React.FC<IMapboxButton> = (props) => {
	const [activeState, setActiveState] = useState(false);
	const [rotateState, setRotateState] = useState(0);
	const [showSidePanelState, setSidePanelState] = useState(false);

	useEffect(() => {
		props.map.on('rotate', () => onRotate());
		props.map.on('showSidePanel', (evt) => setSidePanelState(evt.show));
		props.map.on('buttonGroupChange', (evt) => {
			if (props.buttonGroup === evt.group && props.id !== evt.key) {
				setActiveState(false);
			}
		});
	}, []);

	const onClick = () => {
		if (props.rotateIcon) {
			props.map.easeTo({ bearing: 0 });
			setRotateState(0);
		}
		props.setActive && setActiveState(!activeState);
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		props.onClick?.(!activeState);
		props.buttonGroup && props.map.fire('buttonGroupChange', { group: props.buttonGroup, key: props.id });
	};

	const onRotate = () => {
		if (props.rotateIcon) {
			const angle = props.map.getBearing() * -1;
			setRotateState(angle);
		}
	};
	let iconButton;
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (activeState && props.activeIcon) {
		iconButton = <Icon name={props.activeIcon} type={EIconTypes.LIGHT} />;
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	} else if (props.icon) {
		iconButton = <Icon name={props.icon} type={EIconTypes.LIGHT} />;
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	} else if (props.rawIcon) {
		iconButton = <props.rawIcon />;
	}

	return (
		<React.Suspense fallback={<div />}>
			{/* eslint-disable-next-line react/forbid-dom-props*/}
			<div
				style={{ transform: `rotate(${rotateState}deg)` }}
				className={`mapboxgl-ctrl mapboxgl-ctrl-group ${props.title && 'text'} ${showSidePanelState ? 'sidepanel-visible' : ''}`}>
				<ConditionalWrapper condition={!!props.tooltip} wrapper={(children: React.ReactElement) => <Tooltip title={props.tooltip}>{children}</Tooltip>}>
					<button
						key={`keyTrigger${activeState}`}
						className={`buttonMap ${activeState && '-active'} ${props.title && '-withTitle'} ${props.className && props.className}`}
						onClick={onClick}>
						<div className={props.title ? 'button-divText' : 'button-div'}>
							<span className={'button-icon'}>{iconButton}</span>
							<span className={'button-title'}>{props.title && props.title}</span>
						</div>
					</button>
				</ConditionalWrapper>
			</div>
		</React.Suspense>
	);
};

/* MapboxControl handler */
export const MapboxControl = (options: IMapboxControlOptions): IControl => {
	let controlContainer: HTMLElement;
	let controlMap: mapboxgl.Map | undefined;

	const insertControls = (map: mapboxgl.Map) => {
		controlContainer = document.createElement('div');
		ReactDOM.render(<MapboxButton map={map} {...options} onClick={options.onClick ? options.onClick : () => null} />, controlContainer);
	};

	const onAdd = (map: mapboxgl.Map): HTMLElement => {
		controlMap = map;
		insertControls(map);
		return controlContainer;
	};

	const onRemove = (): void => {
		controlContainer.parentNode.removeChild(controlContainer);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		controlMap = undefined;
	};

	return {
		onAdd: (map: mapboxgl.Map) => onAdd(map),
		onRemove: () => onRemove(),
	};
};
