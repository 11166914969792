import mapboxgl from 'mapbox-gl';
import { TSidePanel } from '../..';
import { CallbackControl } from '../Controls';

export const addControls = (map: mapboxgl.Map, sidepanels: TSidePanel[]): void => {
	sidepanels.map((sidepanel, index) => {
		map.addControl(
			CallbackControl({
				id: sidepanel.id,
				buttonGroup: 'sidePanel',
				onClick: (isActive) => map.fire('showSidePanel', { index: isActive ? index : undefined, show: isActive }),
				tooltip: sidepanel.tooltip,
				icon: sidepanel.icon,
				activeIcon: 'arrow-to-right',
				className: sidepanel.className,
			})
		);
	});
};
