import React, { FC } from 'react';
import './styles.scss';

const Square = () => <div />;
/**
 * ## LoaderSquare
 * Map loader animation
 * @returns A loader animation
 */
export const LoaderSquare: FC = () => {
	return (
		<div className={'container'}>
			<div className={'square_loader'}>
				<Square />
				<Square />
				<Square />
				<Square />
				<Square />
				<Square />
				<Square />
				<Square />
				<Square />
			</div>
		</div>
	);
};
