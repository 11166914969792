import React from 'react';
import { Icon, EIconTypes } from '..';

import { Button, Dropdown, DropDownProps } from 'antd';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/**
 * @param onClick onClick handler for the dropdown button
 * @param icon Icon to be rendered as dropdown button
 * @param overlay The DropDown content. Required Antd Prop
 * extends {@link DropDownProps}
 */
export interface IDropDownProps extends DropDownProps {
	onClick?: () => void;
	overlay: JSX.Element;
	/**Extends {@link IconName} from FontAwesome for list of allowed icon names */
	icon: IconDefinition;
}

/**
 * ## Dropdown 
 * @example <DropDown
				icon={'globe-europe'}
				className={props.className}
				placement={props.placement}
				trigger={['click']}
				overlay={
					<Menu className={'dropdown-menu'}>
						<>
						<div> Some content </div>
						</>
					</Menu>
			}></DropDown>
 * @param props See {@link IDropDownProps}
 * @returns a responsive dropdown menu 
 */
export const DropDown: React.FC<IDropDownProps> = (props) => {
	const { overlay, ...remainingProps } = props;
	return (
		<div>
			<Dropdown {...remainingProps} overlay={props.overlay} arrow>
				<Button onClick={props.onClick && props.onClick} type={'link'} shape={'circle'} size={'large'}>
					{props.icon ? <Icon name={props.icon.iconName} icon={props.icon} type={EIconTypes.REGULAR} /> : <span>{props.icon}</span>}
				</Button>
			</Dropdown>
		</div>
	);
};
