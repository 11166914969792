/* eslint-disable-next-line eslint-comments/disable-enable-pair*/
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { Drawer } from '..';
import { IconName } from '../../';
//Needed for mapcontrols etc
import 'mapbox-gl/dist/mapbox-gl.css';
import { addControls } from './helpers';
import mapboxgl, { IControl } from 'mapbox-gl';
import { IMapboxControlOptions, MapboxControl } from './Controls';

export type TSidePanel = {
	id: string;
	icon: IconName;
	tooltip?: string;
	component: JSX.Element;
	className?: string;
};

type TMapOptions = Omit<mapboxgl.MapboxOptions, 'container'>;
type TMapControlPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
interface IMapControlButton {
	handlers: IControl;
	position: TMapControlPosition;
}

export interface IMapOptions extends TMapOptions {
	mapStyle?: EMapType;
	autoResize?: boolean;
	sidePanels?: TSidePanel[];
	buttons?: IMapControlButton[];
}

export enum EMapType {
	LIGHT = 'mapbox://styles/mapbox/light-v10',
	DARK = 'mapbox://styles/mapbox/dark-v10',
	SATELLITE = 'mapbox://styles/mapbox/satellite-v9',
	SATELLITE_STREETS = 'mapbox://styles/mapbox/satellite-streets-v11',
	STREETS = 'mapbox://styles/mapbox/streets-v11',
	OUTDOORS = 'mapbox://styles/mapbox/outdoors-v11',
	NAVIGATION_DAY = 'mapbox://styles/mapbox/navigation-day-v1',
	NAVIGATION_NIGHT = 'mapbox://styles/mapbox/navigation-night-v1',
}

/* Create Map Control function */
export const createMapButton = (options: IMapboxControlOptions, position: TMapControlPosition): IMapControlButton => {
	const mapboxControl = MapboxControl({
		...options,
	});

	const handleOnAdd = (map: mapboxgl.Map): HTMLElement => {
		return mapboxControl.onAdd(map);
	};

	const handleOnRemove = (map: mapboxgl.Map) => {
		return mapboxControl.onRemove(map);
	};

	return {
		handlers: {
			onAdd: handleOnAdd,
			onRemove: handleOnRemove,
		},
		position: position,
	};
};

/**
 * ## useMap
 *
 * Handle everything map related, and initialize a new map component easily.
 **/
export const useMap = (accessToken: string, options: IMapOptions) => {
	/* MapboxGL access token */
	mapboxgl.accessToken = accessToken;
	const mapContainer = useRef<HTMLDivElement>(null);
	const [getActiveDrawerState, setActiveDrawerState] = useState<number | undefined>();
	const [mapObj, setMapObj] = useState<mapboxgl.Map>();

	const loadMap = () => {
		if (mapContainer.current) {
			const map = new mapboxgl.Map({
				...options,
				container: mapContainer.current,
				style: options.mapStyle,
				attributionControl: false,
			});
			map.once('load', () => handleLoad(map));
			setMapObj(map);
		}
	};

	const handleLoad = (map: mapboxgl.Map) => {
		options.sidePanels && addControls(map, options.sidePanels);
		options.buttons &&
			options.buttons.map((button) => {
				map.addControl(button.handlers, button.position);
			});
		//Handles set of activeDrawer
		map.on('showSidePanel', (evt) => setActiveDrawerState(evt.index));
	};

	useEffect(() => {
		loadMap();
	}, []);

	/**
	 * Handldles autoresize
	 */
	useEffect(() => {
		if (options.autoResize)
			window.addEventListener('resize', () =>
				setTimeout(() => {
					mapObj?.resize();
				}, 100)
			);
	}, [options.autoResize]);

	const renderMap = (
		<div className={'map'}>
			<Drawer width={300} renderInDom mask={false} visible={getActiveDrawerState !== undefined ? true : false} closable={false}>
				{getActiveDrawerState !== undefined && options.sidePanels[getActiveDrawerState].component}
			</Drawer>
			<div className={'map-container'} ref={mapContainer}></div>
		</div>
	);

	return {
		renderMap,
		mapObj,
	};
};

/* eslint-enable @typescript-eslint/naming-convention */
