import React, { RefObject } from 'react';
import { Icon, EIconTypes } from '../..';
import { faTimes } from '@naviair-utm/react-fortawesome/icons/light/faTimes';
import { faSearch } from '@naviair-utm/react-fortawesome/icons/regular/faSearch';

/**
 * ## ISearchInput
 * @param input The input JSX.Element to be processed
 * @param clickRef The ref object
 * @param showSearch Show search inputfield on mobile
 * @param onIconClick Mobile iconclick handler
 */
interface ISearchInput {
	input: JSX.Element;
	clickRef: RefObject<HTMLDivElement>;
	showSearch?: boolean;
	onIconClick?: () => void;
	onSearchIconClick?: () => void;
	className?: React.ReactNode;
	children?: React.ReactNode;
}

/**
 * ## Mobile
 * @param props see {@link ISearchInput}
 * @returns Mobile search input component suitable for Mobile viewport
 */
const Mobile: React.FC<ISearchInput> = (props) => (
	<div key={'search_mobile'} className={`searchMobile${props.showSearch ? 'Extended' : ''} ${props.className}`} ref={props.clickRef}>
		<div key={'input_wrapper_mobile'} className={'inputWrapper'}>
			<div className={'iconWrapperMobile'}>
				<div className={'iconMobile'}>
					{props.showSearch ? (
						<Icon name={'times'} icon={faTimes} key={'search-icon-mobile-times'} type={EIconTypes.LIGHT} onClick={props.onIconClick} />
					) : (
						<Icon name={'search'} icon={faSearch} key={'search-icon-mobile-search'} type={EIconTypes.REGULAR} onClick={props.onIconClick} />
					)}
				</div>
			</div>
			{props.showSearch && (
				<div className={'iconWrapperMobile searchIconMobile'}>
					<div className={'iconMobile'}>
						<Icon name={'search'} icon={faSearch} key={'search-icon-mobile'} type={EIconTypes.REGULAR} onClick={props.onSearchIconClick} />
					</div>
				</div>
			)}
			{props.input}
		</div>
		{props.children}
	</div>
);

/**
 * ## Desktop
 * @param props see {@link ISearchInput}
 * @returns Desktop search input component suitable for Desktop viewport
 */
const Desktop: React.FC<ISearchInput> = (props) => {
	return (
		<div key={'search'} className={`search ${props.className}`} ref={props.clickRef}>
			<div key={'input_wrapper'} className={'inputWrapper'}>
				{props.input}
				<div className={'iconWrapper'}>
					<div className={'icon'}>
						<Icon name={'search'} icon={faSearch} key={'search-icon'} type={EIconTypes.REGULAR} onClick={props.onSearchIconClick} />
					</div>
				</div>
			</div>
			{props.children}
		</div>
	);
};

/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Format components to SearchInput.<Component>
 */
export const SearchInput = {
	Desktop: Desktop,
	Mobile: Mobile,
};
/* eslint-enable @typescript-eslint/naming-convention */
