import React from 'react';
import { Badge } from '../../../../Styles/Styles';
import { Badge as AntdBadge } from 'antd';
import './styles.scss';

/**
 * ## ITableFilterBadge
 * @param value The value string
 * @param title The badge title
 * @param activeKey Current active badge key
 * @param count Content count for particular badge
 * @param onClick onClick action handler
 * @param left Align badges left section
 */
interface ITableFilterBadgeProps {
	value: string;
	title: string;
	activeKeys: string[];
	count: number;
	onClick?: (key: string) => void;
	left?: boolean;
}

/**
 * ## TableFilterBadgeProps
 * @param props see {@link ITableFilterBadgeProps}
 * @returns A filter badge component
 */
export const TableFilterBadge: React.FC<ITableFilterBadgeProps> = (props) => {
	const active = props.activeKeys.includes(props.value);

	return (
		<div className={`tableFilterBadge ${active && '-active'} ${props.left && '-left'}`} onClick={() => props.onClick(props.value)}>
			{props.title}
			{/* eslint-disable-next-line react/forbid-component-props */}
			<AntdBadge showZero style={active ? Badge.active : Badge.default} count={props.count} />
		</div>
	);
};
