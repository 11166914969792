import React from 'react';
import { Icon, EIconTypes, IconName } from '../';
import './styles.scss';

/**
 * @param fill Sets flex to none if set
 * @param icon The icon to be rendered in the title
 * @param title The title string
 * @param textAlign Text alignment in the card container
 */

export interface ICardProps {
	fill?: boolean;
	/**Extends {@link IconName} from FontAwesome for list of allowed icon names */
	icon?: IconName;
	title?: string;
	type?: 'small' | 'regular';
	textAlign?: 'left' | 'center' | 'right';
	children?: React.ReactNode;
}

/**
 * ## Card
 * Wrap content in card-container
 * @example <Card key={'some_key'} fill title={'some_title'} icon={'some_icon}>
					<h1>This is rendered inside the card</h1>
					<p>Some card text</p>
			</Card>
 * @param props See {@link CardProps}
 * @returns a card wrapper
 */
export const Card: React.FC<ICardProps> = (props) => {
	return (
		<div className={'card_wrapper'}>
			<div className={`${props.fill ? 'card-fill' : 'card'} ${props.type === 'small' ? 'card-small' : ''}`}>
				{(props.title || props.icon) && (
					<div>
						{props.icon && (
							<div className={'icon'}>
								<Icon name={props.icon} type={EIconTypes.LIGHT} />
							</div>
						)}
						{props.title && <div className={'title'}>{props.title}</div>}
					</div>
				)}
				{/* eslint-disable-next-line react/forbid-dom-props*/}
				<div className={'card_content'} style={{ textAlign: props.textAlign ? props.textAlign : 'left' }}>
					{props.children}
				</div>
			</div>
		</div>
	);
};
