import React from 'react';
import './styles.scss';

export interface ISingleLayoutProps {
	/**Pass to remove default padding*/
	fill?: boolean;
	children?: React.ReactNode;
}

/**
 * ## SingleLayout
 *
 * Creates the default Single Layout which is used as a container for full- or near fullpage Views.
 *
 * Pass contents as children.
 *
 * @example
 * <SingleLayout>
 * 	{props.children}
 *  </SingleLayout>
 *
 * @param props see {@link ISingleLayoutProps}
 * @returns two div wrappers with a specific styleset.
 */
export const SingleLayout: React.FC<ISingleLayoutProps> = (props) => {
	return (
		<div className={'singleLayout'}>
			<div className={`singleContent${props.fill ? 'Fill' : ''}`}>{props.children}</div>
		</div>
	);
};
