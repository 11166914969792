import { Button, DrawerProps, Modal, Drawer as AntdDrawer } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import './styles.scss';

/**
 * @param onClose Function to be called onClose.
 * @param onDownload Function to be called onDownload
 * @param onSave Function to be called onSave
 * @param renderInDom Render inside dom
 * @param standardFooter Render standard drawer footer
 * @param title Render title string
 * extends {@link DrawerProps}
 */

export interface IDrawerProps extends DrawerProps {
	onClose?: () => void;
	onDownload?: () => void;
	onSave?: () => void;
	renderInDom?: boolean;
	standardFooter?: boolean;
	title?: string | undefined;
	visible?: boolean;
	disableCancelConfirm?: boolean;
}

/**
 * Drawer ref interface
 * Defines our component functions.
 */
export interface IDrawerRef {
	open: () => void;
	close: () => void;
}

/**
 * ## Drawer
 * @example <Drawer
 * 				renderInDom
 * 				width={300}
 * 				mask={false}
 * 				ref={drawerRef}
 * 				>{props.children}</Drawer>
 * @param props See {@link IDrawerProps}
 * @returns A drawer component
 */
export const Drawer = forwardRef<IDrawerRef, React.PropsWithChildren<IDrawerProps>>((props, ref) => {
	const [getShowState, setShowState] = useState<boolean>(false);
	const [, modalContextHolder] = Modal.useModal();

	/**
	 * Allows direct interaction with the DOM element, and gives us control over the return value
	 * Open and close the drawer.
	 * @param ref The ref drawer element to handle
	 */
	useImperativeHandle(ref, () => ({
		open(): void {
			setShowState(true);
		},
		close(): void {
			handleClose();
		},
	}));

	useEffect(() => {
		if (props.visible !== undefined) {
			props.visible ? setShowState(true) : handleClose();
		}
	}, [props.visible]);

	/**
	 * Close the drawer
	 * If props.onClose is set, we call the function.
	 * If not, just close.
	 */
	const handleClose = () => {
		props.onClose?.();
		setShowState(false);
	};

	/**
	 * Save and close the drawer
	 * If props.onSave is set, call the function
	 * If not, call {@function handleClose()}
	 */
	const handleSave = () => {
		props.onSave?.();
		handleClose();
	};

	/**
	 * Header wrapper element for props.title.
	 * Will only render if props.title is set.
	 * @returns a header drawer element
	 */
	const DrawerHeader: React.FC = () => (
		<div className={'drawerHeader'}>
			<div className={'left'}>{props.title}</div>
			{props.onDownload && (
				<div className={'right'}>
					<Button icon={<DownloadOutlined />} onClick={props.onDownload}>
						{'Download'}
					</Button>
				</div>
			)}
		</div>
	);

	//TODO: FIGURE OUT WHAT TODO WITH TRANSLATIONS AND STANDARD STUFF

	/**
	 * Will render props.standardFooter element if set to true.
	 * @returns a footer element for the drawer.
	 */
	const Footer: React.FC = () => (
		<div className={'drawerButton'}>
			<Button onClick={handleClose} className={'cancel'}>
				{'Annuller'}
			</Button>
			<Button type={'primary'} onClick={handleSave}>
				{'Gem'}
			</Button>
		</div>
	);
	// Remove 'visible' prop from 'remainingProps' as 'AntdDrawer#visible' prop is deprecated (use #open) now.
	const { renderInDom, visible, ...remainingProps } = props;

	return (
		<AntdDrawer
			title={props.title ? <DrawerHeader /> : undefined}
			// eslint-disable-next-line react/forbid-component-props
			style={{ position: renderInDom ? 'absolute' : undefined, textAlign: 'left' }}
			getContainer={renderInDom ? false : undefined}
			footer={props.standardFooter ? <Footer /> : undefined}
			open={getShowState}
			onClose={handleClose}
			{...remainingProps}>
			{props.children}
			<div>{modalContextHolder}</div>
		</AntdDrawer>
	);
});
