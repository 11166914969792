import { IControl } from 'mapbox-gl';
import { MapboxControl } from '../';
import { IconName } from '../../../';

export interface ICallbackControlOptions {
	onClick: (isActive?: boolean) => void;
	setActive?: boolean;
	icon?: IconName;
	activeIcon?: IconName;
	id: string;
	buttonGroup: string;
	className?: string;
	tooltip: string;
}

export const CallbackControl = (options: ICallbackControlOptions): IControl => {
	return MapboxControl({
		onClick: options.onClick,
		icon: options.icon ? options.icon : 'layer-group', //fallback
		setActive: true,
		...options,
	});
};
