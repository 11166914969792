import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IconName, fortawesome } from '@naviair-utm/react-fortawesome';

const { FontAwesomeIcon } = fortawesome;
import React, { useEffect, useState } from 'react';
import './styles.scss';

/**
 * Enum for various FontAwesome icon types.
 */
export enum EIconTypes {
	BRANDS = 'fab',
	DUOTONE = 'fad',
	LIGHT = 'fal',
	REGULAR = 'far',
	SOLID = 'fas',
}

export type { IconName };

/**
 * @param name The FontAwesome icon name
 * @param type Icon style as an IconType see {@link IconTypes}
 * @param color Icon color
 * @param rotate Icon rotation in degrees
 * @param onClick  OnClick event
 * @param className Element ClassName
 */
export interface IIconProps {
	/**Extends {@link IconName} from FontAwesome for list of allowed icon names */
	name: IconName;
	icon?: IconDefinition;
	type?: EIconTypes;
	color?: string;
	rotate?: number;
	onClick?: () => void;
	className?: string;
}

/**
 * Import the FontAwesome icon libraries we wish to use.
 */
export const { library } = fortawesome;

/**
 * ## Icon
 * @example <Icon name={'user'} type={EIconTypes.REGULAR} />
 * @returns Icon component with the FontAwesome icon for user.
 */
/* It is currently a requirement to specify both Icon name, and Icon. This is because we still have configuration settings in DB that renders icons by string.
 Therefore, Icon has been made optional. However, if you are using an icon, and defining it by name(string), you need to manually add it to your library. */
export const Icon: React.FC<IIconProps> = (props) => {
	const [iconLoaded, setIconLoaded] = useState(false);

	useEffect(() => {
		props.icon && library.add(props.icon);
		setIconLoaded(true);
	}, []);

	return iconLoaded ? (
		<FontAwesomeIcon
			icon={[props.type ? props.type : EIconTypes.SOLID, props.name]}
			color={props.color}
			// eslint-disable-next-line react/forbid-component-props
			style={props.rotate && { transform: `rotate(${props.rotate}deg)` }}
			className={`icon ${props.className ? props.className : ''} ${props.onClick ? 'clickable' : ''}`}
			onClick={props.onClick}
		/>
	) : (
		<></>
	);
};
