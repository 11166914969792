/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import { TableProps } from 'antd/lib/table';
import { DownloadOutlined } from '@ant-design/icons';
import { Locale } from 'antd/lib/locale-provider';
import { TableFilter, TTableFilterData } from './TableFilter';
import './styles.scss';
import { Button, ConfigProvider, Divider, message, Table as AntdTable } from 'antd';

/**
 * ## ITableProps
 * @param data Set the table data
 * @param download Show table download button
 * @param filter Show table filter for table
 * @param language Set the language of the table. Uses Antd's locale config
 * @param mobile Set table mobile
 * @param tableName Set the name of the table
 * @param onDownloadClick Download button action handler
 * @extends TableProps
 */
export interface ITableProps<T> extends TableProps<T> {
	data: T[];
	download?: boolean;
	filter?: boolean;
	language?: Locale;
	mobile?: boolean;
	tableName?: string;
	translations?: { filterAll: string };
	onDownloadClick?: () => Promise<void>;
}

/**
 * ## Table
 * @param props.columns should be defined to represent columns in the table
 * @param props.data should be defined with matching parameters, to represent column content in the table
 * @example <Table pagination={false} columns={someCol} data={someData} tableName={'EXAMPLE_DATA'}></Table>
 * @param props See {@link ITableProps}
 * @returns a table component
 */

export const Table = <T extends { _id?: string; name?: string; type?: string | number }>(props: ITableProps<T>): JSX.Element => {
	const [downloadLoadingState, setDownloadLoadingState] = useState(false);
	const [getType, setType] = useState<string[]>();
	const [getFilteredDataSource, setFilteredDataSource] = useState<T[]>();
	const [getFilterDataState, setFilterDataState] = useState<TTableFilterData[]>([]);

	/**
	 * Listen for type changes
	 * call @function handleFilteredState() on change
	 */

	useEffect(() => {
		handleFilteredState(props.data);
	}, [getType, props.dataSource]);

	/**
	 * ## handleDownload
	 * onClick action handler for download button on specific Table.
	 * Download button will only appear if @param props.onDownloadClick is set.
	 */
	const handleDownload = () => {
		setDownloadLoadingState(true);
		props
			.onDownloadClick()
			.catch((err) => {
				message.error(err);
			})
			.finally(() => setDownloadLoadingState(false));
	};

	/**
	 * Map data to types for filter in top
	 * Listen on @param props.data
	 * Update @param getTypeState
	 */
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		const values: TTableFilterData[] = [{ key: '*', count: 0, translation: props.translations?.filterAll ? props.translations.filterAll : 'All' }];
		props.data.map((value) => {
			if (value.type !== undefined) {
				values[0].count += 1;
				const curIndex = values.findIndex((obj) => obj.key === value.type);
				if (curIndex > -1) {
					values[curIndex].count += 1;
				} else {
					values.push({ key: String(value.type), count: 1, translation: String(value.type) });
				}
			}
		});
		setFilterDataState(values);
	}, [props.data]);

	/**
	 * ## handleFilteredState
	 * Listen for active filters, and set table data to be displayed.
	 * @param dataSource The type T[] dataSource to be processed
	 */
	const handleFilteredState = (dataSource: T[]) => {
		if (getType === undefined || getType.includes('*')) {
			setFilteredDataSource(dataSource);
		} else {
			setFilteredDataSource(dataSource.filter((item) => getType.includes(String(item.type))));
		}
	};
	return (
		<>
			<div className={'tableHeader'}>
				<div className={'section left'}>{props.filter && <TableFilter data={getFilterDataState} onActiveChange={setType} />}</div>
				<div className={'section right'}>
					{props.download && (
						<div className={'right'}>
							<Button onClick={handleDownload} loading={downloadLoadingState} icon={<DownloadOutlined />} type={'primary'}>
								{'Download'}
							</Button>
						</div>
					)}
				</div>
			</div>
			{(props.download || props.filter) && <Divider />}
			<div className={'table'}>
				<h2 className={'tableName'}>{props.tableName && props.tableName}</h2>
				<ConfigProvider locale={props.language}>
					<AntdTable dataSource={props.filter ? getFilteredDataSource : props.data} {...props} />
				</ConfigProvider>
			</div>
		</>
	);
};

/* eslint-enable @typescript-eslint/naming-convention */
