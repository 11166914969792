import {
	IDocument,
	IDocumentCollapse,
	IDocumentForm,
	IDocumentHtml,
	IDocumentPdf,
	IDocumentTable,
	IFormInput,
	TDocumentTableRow,
} from '@naviair-utm/node-shared-interfaces';
import moment from 'moment';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import './styles.scss';
import { Button, Collapse, Form, Input, Result, Table } from 'antd';
import { EIconTypes, Icon } from '../Icon';
import { Document as PDFDocument, Page } from 'react-pdf/dist/esm/entry.webpack'; //Special import for webpack4
import { DocumentProps as PDFDocumentProps, PageProps as PDFPageProps } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { faArrowLeft } from '@naviair-utm/react-fortawesome/icons/solid/faArrowLeft';
import { faArrowRight } from '@naviair-utm/react-fortawesome/icons/solid/faArrowRight';
import { faDownload } from '@naviair-utm/react-fortawesome/icons/solid/faDownload';
import { TailSpin } from 'react-loader-spinner';
import { Styles } from '../../Styles';
const Panel = Collapse.Panel;

const DocumentTable: React.FC<IDocumentTable> = (props) => {
	const cols = props.columns.map((col) => {
		return { title: col.title, dataIndex: col.index };
	});
	const rows = props.rows;

	return (
		<div className={'documentTable'}>
			{props.title && <div className={'title'}>{props.title}</div>}
			<Table<TDocumentTableRow> scroll={{ x: true }} columns={cols} dataSource={rows} pagination={false} rowKey={() => `row_${props.title}_${uuid()}`} />
		</div>
	);
};

const DocumentHtml: React.FC<IDocumentHtml> = (props) => {
	return (
		<div
			className={'documentHtml'}
			dangerouslySetInnerHTML={{
				// eslint-disable-next-line @typescript-eslint/naming-convention
				__html: props.data,
			}}
		/>
	);
};

const DocumentCollapse: React.FC<IDocumentCollapse> = (props) => {
	return (
		<div className={'documentCollapse'}>
			<Collapse expandIconPosition={'right'} accordion>
				{props.data.map((entry, index) => {
					return (
						<Panel header={entry.title} key={`collapse_${index}`}>
							<div
								dangerouslySetInnerHTML={{
									// eslint-disable-next-line @typescript-eslint/naming-convention
									__html: entry.details,
								}}
							/>
						</Panel>
					);
				})}
			</Collapse>
		</div>
	);
};

export type TFormValues = { [key: string]: string | number };

export interface ISubmitFormResponse extends IDocumentForm {
	values: TFormValues;
}

export const DocumentForm: React.FC<{
	/* The Application form object. */
	form: IDocumentForm;
	/* Fetch Call onSend prop. Values defines the values returned on form submission. */
	onSubmit: (form: ISubmitFormResponse) => Promise<void>;
}> = (props) => {
	const [getFormState, setFormState] = useState({ error: false, submitted: false, loading: false });

	const onSubmit = (values?: TFormValues) => {
		setFormState({ ...getFormState, loading: true });
		props
			.onSubmit({ values: values, ...props.form })
			.then(() => setFormState({ error: false, submitted: true, loading: false }))
			.catch(() => setFormState({ error: true, submitted: false, loading: false }));
	};

	/* Prefix Icons should be deep imported manually for specific project, as Icons are defined by names in configuration. They will not appear, if not added to icon library. */
	const FormInput: React.FC<IFormInput> = (formInputProps) => {
		return (
			<Form.Item
				name={formInputProps.inputId}
				rules={[{ required: formInputProps.required, message: `${formInputProps.onInputErrorMessage}`, type: formInputProps.inputType }]}>
				{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
				<Input
					required={formInputProps.required}
					placeholder={formInputProps.placeholder}
					autoFocus={formInputProps.autoFocus}
					addonBefore={formInputProps.addonBefore}
					key={formInputProps.inputId}
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					prefix={formInputProps.prefixIcon && <Icon name={formInputProps.prefixIcon} type={EIconTypes.REGULAR} />}
				/>
			</Form.Item>
		);
	};

	return (
		<>
			{getFormState.error && <Result status={'error'} title={props.form.onFormErrorMessage.title} subTitle={props.form.onFormErrorMessage.subtitle} />}
			{getFormState.submitted && (
				<Result status={'success'} title={props.form.onFormSuccessMessage.title} subTitle={props.form.onFormSuccessMessage.subtitle} />
			)}
			{!getFormState.error && !getFormState.submitted && (
				<Form name={'applicationForm'} className={'documentForm'} onFinish={onSubmit}>
					{props.form.input.map((input, i) => (
						<FormInput key={`FormInput_${i}`} {...input} />
					))}
					<Form.Item className={'button'}>
						<Button loading={getFormState.loading} type={'primary'} htmlType={'submit'}>
							{props.form.submitButtonText}
						</Button>
					</Form.Item>
				</Form>
			)}
		</>
	);
};

export interface IDocumentPdfProps extends IDocumentPdf {
	/**
	 * The "file" property is overwritten through the data field, so do not bother setting it in pdfDocumentprops
	 */
	pdfDocumentProps?: PDFDocumentProps;
	pdfPageProps?: PDFPageProps;
}

export const DocumentPdf: React.FC<IDocumentPdfProps> = ({ data, title, pdfDocumentProps = {}, pdfPageProps = {} }) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const PDF_DATA_URL = 'data:application/pdf;base64,'; //https://github.com/wojtekmaj/react-pdf/wiki/Frequently-Asked-Questions#how-do-i-load-a-pdf-from-base64
	const pdfFile = PDF_DATA_URL.concat(data);

	const [numberOfPages, setNumberOfPages] = useState<number>();
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumberOfPages(numPages);
	};

	const changePage = (offset: number) => {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	};

	//Download function for PDF
	const onDownload = () => {
		const byteString = window.atob(data);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: 'application/pdf' });
		const url = URL.createObjectURL(blob);

		// to open the PDF in a new window
		window.open(url, '_blank');
	};

	const loader = () => (
		<div className={'loader'}>
			<TailSpin width={20} height={20} color={Styles.BrandColor} />
		</div>
	);

	return (
		<div className={'documentPdf'}>
			<div className={'buttonContainer'}>
				<div className={'buttonLeft'} />
				<div className={'buttonCenter'}>
					<Button disabled={pageNumber <= 1} onClick={() => changePage(-1)}>
						<Icon name={'arrow-left'} icon={faArrowLeft} type={EIconTypes.SOLID} />
					</Button>
					{/*eslint-disable-next-line @typescript-eslint/no-unnecessary-condition*/}
					<Button>{<p>{`${pageNumber}/${numberOfPages ?? '?'}`}</p>}</Button>
					{/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
					<Button disabled={!numberOfPages || pageNumber >= numberOfPages} onClick={() => changePage(1)}>
						<Icon name={'arrow-right'} icon={faArrowRight} type={EIconTypes.SOLID} />
					</Button>
				</div>
				<div className={'buttonRight'}>
					<Button onClick={() => onDownload()}>
						<Icon name={'download'} icon={faDownload} type={EIconTypes.SOLID} />
					</Button>
				</div>
			</div>
			<div>
				<PDFDocument onLoadSuccess={onDocumentLoadSuccess} {...pdfDocumentProps} file={pdfFile} loading={loader()}>
					<Page pageNumber={pageNumber} {...pdfPageProps} loading={loader()} />
				</PDFDocument>
			</div>
		</div>
	);
};

export interface IDocumentProps {
	updateText?: string;
	onFormSubmit?: (form: ISubmitFormResponse) => Promise<void>;
	children?: React.ReactNode;
}

/**
 * ## Document
 *
 * Used to display Documents, typically from the database configuration.
 *
 * @example <Document {...configuration.documents[configuration.settings.app.documents[language][documentIdentifier]]} >
 * {props.children}
 * </Document>
 * @param props See {@link IDocumentProps} which extends {@link IDocument}
 * @default updateText = 'Senest opdateret den'
 * @returns a Document variant **based on the props content field: Html, Table or Collapse.**
 */
export const Document: React.FC<IDocumentProps & IDocument> = (props) => {
	const doc = props;
	return (
		<>
			{doc.content.map(({ html, table, collapse, form, pdf }, index) => {
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				if (html) {
					return <DocumentHtml key={`section_${index}`} {...html} />;
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				} else if (table) {
					return <DocumentTable key={`section_${index}`} {...table} />;
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				} else if (collapse) {
					return <DocumentCollapse key={`section_${index}`} {...collapse} />;
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				} else if (form) {
					return <DocumentForm onSubmit={props.onFormSubmit} key={`section_${index}`} form={form} />;
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				} else if (pdf) {
					return <DocumentPdf key={`section_${index}`} {...pdf} />;
				}
			})}
			{props.children}
			<div className={'documentDate'}>
				{props.updateText ? props.updateText : 'Senest opdateret den'} {moment.unix(doc.lastUpdated).format('DD.MM.YYYY')}
			</div>
		</>
	);
};
